// @flow strict
import React from 'react';
import styles from './Footer.module.scss';
import { useSiteMetadata } from '../../hooks';

const Footer = () => {
  const { author, url, address, title } = useSiteMetadata();
  const addressUrl = `https://maps.google.com?q=${address}`;
  const phoneNumber = `${author.contacts.phoneNumber.slice(0,3)}-${author.contacts.phoneNumber.slice(3,10)}`;
  const phoneNumberUrl = `tel://${author.contacts.phoneNumber}`;
  return (
    <div className={styles.footer}>
      <p>{title} כתובת: <a href={addressUrl} target="_blank" rel="noreferrer noopener">{address}</a>, טלפון: <a href={phoneNumberUrl}>{phoneNumber}</a> מירה</p>
    </div>
  );
}

export default Footer;
